import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Ваша фітнес-подорож з Bike&Run
			</title>
			<meta name={"description"} content={"Викуйте свою силу, виліпіть свій успіх - відкрийте для себе спектр фітнесу в Bike&Run\n"} />
			<meta property={"og:title"} content={"Наші послуги | Ваша фітнес-подорож з Bike&Run"} />
			<meta property={"og:description"} content={"Викуйте свою силу, виліпіть свій успіх - відкрийте для себе спектр фітнесу в Bike&Run"} />
			<meta property={"og:image"} content={"https://ua.bikendrun.com/img/1-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.bikendrun.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.bikendrun.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.bikendrun.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.bikendrun.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.bikendrun.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.bikendrun.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наші послуги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					У Bike&Run ми пропонуємо широкий спектр послуг, щоб зробити вашу фітнес-подорож унікальним і корисним досвідом. Від персоналізованих тренувальних планів до спеціалізованих тренувальних просторів, Bike&Run - це місце, де ви зможете набути сили, витривалості та стати здоровішим.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Побудуйте свій фітнес-шлях
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					- Персональні тренування: Працюйте один на один з нашими сертифікованими тренерами, щоб розробити індивідуальний фітнес-план, пристосований до ваших цілей та вподобань.
					<br />
					<br />
					- Групові заняття з фітнесу: Пориньте в атмосферу наших динамічних групових занять, від високоенергетичних тренувань до омолоджуючих занять йогою, розрахованих на всі рівні фізичної підготовки.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Підживлення амбіцій
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					- FlexFuel Bar Treats: Наш власний бар харчування пропонує широкий вибір поживних і смачних закусок, що сприяють досягненню ваших фітнес-цілей та відновленню після тренувань.
					<br />
					<br />
					- Гідратаційний хаб: Залишайтеся свіжими під час тренувань з нашими станціями гідратації, які забезпечать вас енергією протягом усього фітнес-подорожі.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Формуємо витривалість
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					- Зони силових тренувань: Відвідайте наші найсучасніші зони силових тренувань, обладнані різноманітними вагами та тренажерами для будь-якого рівня фізичної підготовки.
					<br />
					<br />
					- Серцево-судинний оазис: Покращуйте здоров'я серцево-судинної системи за допомогою наших найсучасніших кардіотренажерів, роблячи кожне тренування кроком до сильнішого серця.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Атмосфера, керована громадою
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					- Стіна перемоги: Відзначайте свої фітнес-досягнення на нашій Стіні Перемоги, візуальному свідченні рішучості та успіху нашої різноманітної спільноти Bike&Run.
					<br />
					<br />
					- Події спільноти: Беріть участь у групових змаганнях, воркшопах та заходах разом з іншими членами клубу, розвиваючи почуття товариськості та спільного успіху.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Створіть свій фітнес-шедевр
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					- Індивідуальний досвід: Поговоріть з нашою командою, щоб персоналізувати вашу фітнес-подорож, гарантуючи, що кожне тренування буде відповідати вашим унікальним амбіціям та вподобанням.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://ua.bikendrun.com/img/4-3.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Як зв'язатися з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Щоб дізнатися більше про наші послуги або почати ліпити свій фітнес-шедевр, зв'яжіться з нами. Наша віддана команда з радістю допоможе вам на шляху до сили, витривалості та здоровішого, енергійнішого життя.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});